.customer 
{
    .details .row {
        padding: 10px 0px;
        border-bottom: 1px solid #dfebf4;
    }

     .details .row:last-child {
        padding: 10px 0px;
        border-bottom: 0px solid #dfebf4;
    }

    .alphabet {
        display: flex;           /* establish flex container */
        flex-direction: row;  /* make main axis vertical */
        justify-content: center; /* center items vertically, in this case */
        align-items: center; 
        flex-wrap: wrap;

        .letter {
            background-color: #fff;
            border: 1px solid #dfebf4;
            width: 60px;
            margin: 5px;
            font-size: 26px;
            line-height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;        /* for single line flex container */
            align-content: center;
        }
    }
}