
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";


@import "customer";
@import "animal";
@import "home";

.align-right { text-align: right; }
.align-center { text-align: center; }
.align-left { text-align: left; }