.animal 
{

    .details .row {
        padding: 10px 0px;
        border-bottom: 1px solid #dfebf4;

        [class*="col-"] {
            
        }
    }

     .details .row:last-child {
        padding: 10px 0px;
        border-bottom: 0px solid #dfebf4;
    }
}